import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InstallationPage from './components/app/InstallationPage';
import QRCodeScannerPage from './components/app/QRCodeScannerPage';
import Home from './components/panel/Home';
import AppLogin from './components/app/AppLogin';
import Login from './components/panel/Login';
import PrivateRoute from './components/panel/PrivateRoute';
import { ThemeContextProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import PrivateAppRoute from './components/app/PrivateAppRoute';

function App() {
  const [isInstalled, setIsInstalled] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    // Check if the app is installed
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsInstalled(true);
    }

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('beforeinstallprompt', () => { });
    };
  }, []);

  return (
    <ThemeContextProvider>
      <AuthProvider>
        <Router>
          <Routes>
            {isInstalled ? (
              <>
                <Route path="/login" element={<AppLogin />} />
                <Route path="/" element={<PrivateAppRoute><QRCodeScannerPage /></PrivateAppRoute>} />
              </>
            ) : (
              <>
                <Route path="/" element={<InstallationPage deferredPrompt={deferredPrompt} />} />
                <Route path="/panel/login" element={<Login />} />
                <Route path="/panel/*" element={<PrivateRoute><Home /></PrivateRoute>} />
              </>
            )}
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeContextProvider>
  );
}

export default App;
