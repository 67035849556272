import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, TextField, Box, Grid, Typography, IconButton } from '@mui/material';
import api from '../../api/api';
import { jwtDecode } from "jwt-decode";
import { useAuth } from '../../contexts/AuthContext';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const AppLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    setError('');
  }, [email, password]);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    api.post('/api/Auth/login', { email: email, password: password })
      .then(response => {
        var token = response.data?.token
        console.log('Token:', token);
        const userData = jwtDecode(token);
        console.log('User data:', userData);
        login(token);
        navigate('/', { replace: true });
      })
      .catch(error => {
        alert(`Error fetching managers ${error}`);
        console.error('Error fetching managers', error);
        setError('Login failed. Please check your credentials and try again.');
      });
  };

  return (
    <Grid display="flex" padding={4} flexDirection={'column'} justifyContent="center" alignItems="center" height="100vh">
      <Box sx={{
        backgroundImage: 'url(/images/logo_anim.svg)'
      }}
        className="LogoAnim play" />
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Password"
        variant="outlined"
        fullWidth
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
        InputProps={{
          endAdornment: (
            <IconButton
              edge="end"
              onClick={handleTogglePasswordVisibility}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ),
        }}
      />
      {error && <Typography color="error">{error}</Typography>}
      <Button sx={{ marginTop: 2, marginBottom: 8 }} variant="contained" color="primary" fullWidth onClick={handleLogin}>
        Login
      </Button>
    </Grid>
  );
};

export default AppLogin;
