import axios from 'axios';

const api = axios.create({
  baseURL: 'https://assets.hindustangold.com:44341',
  // baseURL: 'https://localhost:44341',
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add a request interceptor to include the token
api.interceptors.request.use(config => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  console.log(`Error Resp ${error}`)
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('jwtToken');
        window.location.href = '/';
      }
  return Promise.reject(error);
});

export default api;
