import { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Avatar, CircularProgress, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Menu, MenuItem, Divider, Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';
import api from '../../api/api';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AssetListing from './AssetListing';
import AssetForm from './AssetForm';
import Logout from './Logout';

function Copyright(props) {
  return (
    <Typography sx={{ bottom: 4 }} variant="body2" color="text.primary" align="center" >
      {'Developed by '}
      <Link color="inherit" href="https://aahana.info/">
        AAHANA ELECTROMATION
      </Link>
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  if (name == null || name == "") return ""

  const initials = name.split(' ')
    .slice(0, 2)
    .map((name) => name[0])
    .join('')

  return {
    sx: {
      ml: 1,
      bgcolor: stringToColor(name),
    },
    children: `${initials}`,
  };
}

export default function Home() {
  const { mode, toggleTheme } = useTheme();
  const { user, logout } = useAuth(); // Added logout to use the logout function

  const [userName, setUserName] = useState("");
  const [role, setRole] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // For managing the avatar menu

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setUserName(user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']);
      setRole(user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
    }
  }, [user]);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
    navigate('/panel/logout');
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute">
        <Toolbar
          sx={{
            pr: '10px', // keep right padding when drawer closed
          }}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {document.title}
          </Typography>
          <IconButton color="inherit" onClick={toggleTheme}>
            {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton>
          <IconButton onClick={handleAvatarClick}>
            <Avatar {...stringAvatar(userName)} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            slotProps={{
              paper: {
                elevation: 3,
                sx: {
                  width: '250px',
                  mt: '10px',
                  '& .MuiAvatar-root': {
                    width: 56,
                    height: 56,
                    ml: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="h6" noWrap>
                {userName}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {role}
              </Typography>
            </Box>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth={false} sx={{ width:'96rem', mt: 4, mb: 4, minHeight: 'calc(100vh - 10rem)' }}>
          <Routes>
            <Route path="/logout" element={<Logout />} />
            <Route path="/" element={<AssetListing />} />
            <Route path="/add" element={<AssetForm />} />
            <Route path="/edit/:id" element={<AssetForm />} />
          </Routes>
        </Container>
        <Copyright />
      </Box>
    </Box>
  );
}
