import { Box, Typography, Button } from '@mui/material';
import { useEffect, useState } from 'react';

const InstallationPage = ({ deferredPrompt }) => {

  const [isIosDevice, setIsIosDevice] = useState(false);

  useEffect(() => {
    setIsIosDevice(isIos());
  }, []);

  const handleInstallClick = () => {
    // Trigger the installation prompt
    const installEvent = deferredPrompt;
    if (installEvent) {
      installEvent.prompt();
      installEvent.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
      });
    }
  };

  return (
    <Box display="flex" flexDirection={'column'} sx={{ height: '100vh', padding: 2, justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{
        backgroundImage: 'url(/images/logo_anim.svg)'
      }}
        className="LogoAnim play" />
      <Typography mb={2} variant='h5'>Welcome to the Hindustan Gold Assets' QR Scanner App</Typography>
      <Typography mb={2} variant='body1'>Install This App to Access Functionalities.</Typography>
      {isIosDevice ? (
        <Box variant='div' >
          <Typography variant='p'>
            To install this app, tap the <strong>Share</strong> button in Safari, then select <strong>Add to Home Screen</strong>.
          </Typography>
        </Box>
      ) : (
        <Button onClick={handleInstallClick} variant='contained'>
          Install App
        </Button>
      )}
    </Box>
  );
};

const isIos = () => {
  const userAgent = navigator.userAgent;

  // iOS detection based on the user agent string
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};



export default InstallationPage;
