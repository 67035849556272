import React, { useState, useEffect, useRef } from 'react';
import { BrowserQRCodeReader } from '@zxing/browser';
import { Box, Typography, CssBaseline, AppBar, Toolbar, IconButton, Menu, Divider, MenuItem, Avatar, CircularProgress, Card, CardContent, Grid, CardActions, Button } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InventoryIcon from '@mui/icons-material/Inventory';
import LabelIcon from '@mui/icons-material/Label';
import LightModeIcon from '@mui/icons-material/LightMode';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import ArticleIcon from '@mui/icons-material/Article';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import RuleIcon from '@mui/icons-material/Rule';
import { useTheme } from '../../contexts/ThemeContext';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  if (name == null || name == "") return ""

  const initials = name.split(' ')
    .slice(0, 2)
    .map((name) => name[0])
    .join('')

  return {
    sx: {
      ml: 1,
      bgcolor: stringToColor(name),
    },
    children: `${initials}`,
  };
}

const QRCodeScannerPage = () => {

  const { mode, toggleTheme } = useTheme();
  const { user, logout } = useAuth(); // Added logout to use the logout function

  const [userName, setUserName] = useState("");
  const [role, setRole] = useState(null);

  const [loading, setLoading] = useState(false);

  const [currentAsset, setCurrentAsset] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const [containerHeight, setContainerHeight] = useState('100vh');

  const videoRef = useRef(null);

  useEffect(() => {
    const updateContainerHeight = () => {
      const appBar = document.querySelector('.app-bar');
      if (appBar) {
        const appBarHeight = appBar.offsetHeight;
        setContainerHeight(`calc(100vh - ${appBarHeight}px)`);
      }
    };

    updateContainerHeight();
    window.addEventListener('resize', updateContainerHeight);

    return () => {
      window.removeEventListener('resize', updateContainerHeight);
    };
  }, []);

  const fetchAssets = (tag) => {
    setLoading(true);
    api.get(`/api/Assets/t/${tag}`)
      .then(response => {
        const data = response.data;
        if (data == null) {
          setLoading(false);
        } else {
          setCurrentAsset(data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('There was an error fetching the assets!', error);
        setLoading(false);
      });
  };

  useEffect(() => {

    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('getUserMedia is not supported in this browser.');
      return;
    }

    let codeReader = new BrowserQRCodeReader();
    codeReader.decodeFromVideoDevice(null, videoRef.current, (result, err) => {
      if (result) {
        fetchAssets(result.getText());
      }
      if (err && err.name !== 'NotFoundException') {
        console.error('QR Code scan error:', err);
      }
    });

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        videoRef.srcObject = null;
      }
    };
  }, []);

  useEffect(() => {
    if (user) {
      setUserName(user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name']);
      setRole(user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']);
    }
  }, [user]);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();

    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach(track => track.stop());
      videoRef.srcObject = null;
    }

    logout();
    navigate('/login', { replace: true });
  };


  return (
    <Box sx={{ display: 'flex' }} flexDirection={'column'}>
      <CssBaseline />
      <AppBar position="absolute" className="app-bar">
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            {document.title}
          </Typography>
          <IconButton color="inherit" onClick={toggleTheme}>
            {mode === 'light' ? <DarkModeIcon /> : <LightModeIcon />}
          </IconButton>
          <IconButton onClick={handleAvatarClick}>
            <Avatar {...stringAvatar(userName)} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            slotProps={{
              paper: {
                elevation: 3,
                sx: {
                  width: '250px',
                  mt: '10px',
                  '& .MuiAvatar-root': {
                    width: 56,
                    height: 56,
                    ml: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="h6" noWrap>
                {userName}
              </Typography>
              <Typography variant="body2" color="text.secondary" noWrap>
                {role}
              </Typography>
            </Box>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box
        component="main"
        sx={{
          height: containerHeight,
          overflow: 'auto',
        }}
      >
        <Box sx={{ height: '100%', width: '100%' }} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'space-between'}>
          {currentAsset ?
            <Card sx={{ width: '90vw', margin: 2 }}>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={2}>
                    <LabelIcon />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      <strong>Asset Code:</strong> {currentAsset.assetCode}
                    </Typography>
                  </Grid>

                  {role == 'Admin' &&
                    <>
                      <Grid item xs={2}>
                        <InventoryIcon />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1">
                          <strong>Equipment Tag:</strong> {currentAsset.equipmentsTag}
                        </Typography>
                      </Grid>
                    </>
                  }

                  <Grid item xs={2}>
                    <LocationCityIcon />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      <strong>Supplier Name:</strong> {currentAsset.supplierName}
                    </Typography>
                  </Grid>

                  {(role == 'Admin' || role == 'Auditor') &&
                    <>
                      <Grid item xs={2}>
                        <ReceiptLongIcon />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1">
                          <strong>Invoice No:</strong> {currentAsset.invoiceNo}
                        </Typography>
                      </Grid>
                    </>
                  }

                  <Grid item xs={2}>
                    <ArticleIcon />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography variant="body1">
                      <strong>Description:</strong> {currentAsset.description}
                    </Typography>
                  </Grid>

                  {role == 'Admin' &&
                    <>
                      <Grid item xs={2}>
                        <RuleIcon />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1">
                          <strong>Qty</strong> {currentAsset.qty}
                        </Typography>
                      </Grid>
                    </>
                  }

                  {role != 'Common' &&
                    <>
                      <Grid item xs={2}>
                        <CalendarTodayIcon />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1">
                          <strong>Date of Acquisition:</strong> {currentAsset.dateOfAcquiSition}
                        </Typography>
                      </Grid>
                    </>
                  }

                  {(role == 'Admin' || role == 'Auditor') &&
                    <>
                      <Grid item xs={2}>
                        <LocationOnIcon />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1">
                          <strong>Location:</strong> {currentAsset.location}
                        </Typography>
                      </Grid>
                    </>
                  }

                  {role != 'Common' &&
                    <>
                      <Grid item xs={2}>
                        <CurrencyRupeeIcon />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="body1">
                          <strong>Value:</strong> {currentAsset.value}
                        </Typography>
                      </Grid>
                    </>
                  }
                </Grid>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => {
                  window.location.reload();
                }} color="primary">
                  Back
                </Button>
              </CardActions>
            </Card>
            :
            loading ?
              <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <CircularProgress sx={{ width: '100%' }} />
              </Box>
              :
              <>
                <video ref={videoRef} width="100%" height="100%" style={{ objectFit: 'cover' }} />
                <Typography margin={4} variant='body1'>Scan QR Code on assets to get the detail.</Typography>
              </>}
        </Box>
      </Box>
    </Box>
  );
};

export default QRCodeScannerPage;
