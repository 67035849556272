import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Paper, Typography, Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../api/api';

const AssetForm = () => {
    const [asset, setAsset] = useState({
        assetCode: '',
        equipmentsTag: '',
        supplierName: '',
        invoiceNo: '',
        description: '',
        qty: '',
        dateOfAcquiSition: '',
        location: '',
        value: '',
        remark1: '',
        remark2: '',
    });
    const [isEdit, setIsEdit] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setIsEdit(true);
            api.get(`/api/Assets/${id}`)
                .then(response => {
                    setAsset(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the asset data!', error);
                });
        }
    }, [id]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAsset(prevAsset => ({
            ...prevAsset,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const request = isEdit
            ? api.put(`/api/Assets/${id}`, asset)
            : api.post('/api/Assets', [asset]);

        request
            .then(response => {
                navigate('/panel');
            })
            .catch(error => {
                console.error('There was an error saving the asset!', error);
            });
    };


    const handleCancel = () => {
        navigate('/panel');
    };

    return (
        <Container>
            <Paper style={{ padding: 16 }}>
                <Typography variant="h4" gutterBottom>
                    {isEdit ? 'Update Asset' : 'Add New Asset'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container columnSpacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Asset Code"
                                name="assetCode"
                                value={asset.assetCode}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Equipment Tag"
                                name="equipmentsTag"
                                value={asset.equipmentsTag}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Supplier Name"
                                name="supplierName"
                                value={asset.supplierName}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Invoice No"
                                name="invoiceNo"
                                value={asset.invoiceNo}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                name="description"
                                value={asset.description}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Quantity"
                                name="qty"
                                type="number"
                                value={asset.qty}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Date of Acquisition"
                                name="dateOfAcquiSition"
                                type="date"
                                value={asset.dateOfAcquiSition}
                                onChange={handleChange}
                                fullWidth
                                sx={{
                                    svg: { color: '#fff' },
                                    input: { color: '#fff' },
                                }}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Location"
                                name="location"
                                value={asset.location}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Value"
                                name="value"
                                type="number"
                                value={asset.value}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Remark 1"
                                name="remark1"
                                value={asset.remark1}
                                onChange={handleChange}
                                multiline
                                rows={2}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Remark 2"
                                name="remark2"
                                value={asset.remark2}
                                onChange={handleChange}
                                multiline
                                rows={2}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} justifyContent="flex-end" style={{ marginTop: 16 }}>
                        <Grid item>
                            <Button variant="outlined" color="secondary" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                {isEdit ? 'Update' : 'Add'} Asset
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    );
};

export default AssetForm;
