import { Avatar, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Fab, IconButton, Menu, MenuItem, Paper, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/api';
import { Upload } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import QRCode from 'qrcode.react';
import { useReactToPrint } from "react-to-print";

export default function AssetListing() {

    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [assets, setAssets] = useState([]);
    const [currentAsset, setCurrentAsset] = useState(null);
    const [loading, setLoading] = useState(true);

    const [assetQrCodes, setAssetQrCodes] = useState([]);

    const [openDialog, setOpenDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalAssets, setTotalAssets] = useState(0);

    const qrRef = useRef(null);

    const qrAllRef = useRef(null);

    useEffect(() => {
        fetchAssets(page, rowsPerPage);
    }, [page, rowsPerPage]);

    const fetchAssets = (page, pageSize) => {
        setLoading(true);
        api.get(`/api/Assets`, {
            params: {
                page,
                pageSize
            }
        })
            .then(response => {
                const data = response.data;
                if (data.assets.length == 0 && page > 0) {
                    setPage(page - 1);
                } else {
                    setAssets(data.assets);
                    setTotalAssets(data.totalCount);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the assets!', error);
                setLoading(false);
            });
    };

    const fetchAssetQRCodes = async () => {
        setLoading(true);
        await api.get(`/api/Assets/AllEquipmentsTags`)
            .then(response => {
                const data = response.data;
                setAssetQrCodes(data)
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the assets!', error);
                setLoading(false);
            });
    };

    const handleDeleteAsset = async () => {
        try {
            await api.delete(`/api/Assets/${currentAsset.id}`);
            fetchAssets(page, rowsPerPage);
            handleCloseDialog();
        } catch (error) {
            console.error('Error deleting Asset', error);
        }
    };

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = XLSX.utils.sheet_to_json(sheet);

        console.log(parsedData);

        const formattedData = parsedData.map(item => {
            item = Object.fromEntries(
                Object.entries(item).map(([k, v]) => [k.toLowerCase().replace(/[\n\r]+/g, '').trim(), v])
            );

            let dateOfAcquisition = item['date of acquisition'];

            // Parse the date to the desired format (e.g., 'YYYY-MM-DD')
            if (dateOfAcquisition && typeof dateOfAcquisition === 'string') {
                // Try to parse with known date formats
                const parsedDate = new Date(dateOfAcquisition);
                if (!isNaN(parsedDate)) {
                    dateOfAcquisition = parsedDate.toISOString().split('T')[0]; // Converts to 'YYYY-MM-DD'
                } else {
                    // Handle other formats or set it to null if not parsable
                    dateOfAcquisition = '';
                }
            } else if (typeof dateOfAcquisition === 'number') {
                // Handle Excel date as a serial number
                const parsedDate = new Date(Math.round((dateOfAcquisition - 25569) * 86400 * 1000));
                dateOfAcquisition = parsedDate.toISOString().split('T')[0];
            }

            return {
                assetCode: item['asset code']?.toString(),
                equipmentsTag: item['equipments tag']?.toString(),
                supplierName: item['supplier name']?.toString() ?? '',
                invoiceNo: item['invoice no.']?.toString() ?? '',
                description: item['description']?.toString() ?? '',
                qty: item['qty'] ?? 0,
                dateOfAcquiSition: dateOfAcquisition,
                location: item['location']?.toString() ?? '',
                value: item['value'] ?? 0,
                remark1: item['remark']?.toString() ?? '',
            };
        });

        console.log(formattedData);

        try {
            await api.post('/api/Assets', formattedData);
            setMessage('Assets imported successfully');
            if (page == 0) {
                fetchAssets(page, rowsPerPage);
            } else {
                setPage(0);
            }
        } catch (error) {
            console.error('There was an error importing the assets!', error);
            setMessage('Failed to import assets');
        }
    };

    const handleMenuOpen = (event, asset) => {
        setCurrentAsset(asset);
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
        handleMenuClose();
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setCurrentAsset(null);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const triggerFileInput = () => {
        document.getElementById('file-upload').click();
    };

    const handlePrint = useReactToPrint({ content: () => qrRef.current });

    const handlePrintAll = useReactToPrint({ content: () => qrAllRef.current });

    const printAll = async () => {
        await fetchAssetQRCodes();
        handlePrintAll();
    }

    const handlePrintQRCode = () => {
        handleMenuClose();
        handlePrint();
    };

    return (<>
        {loading ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <CircularProgress sx={{ width: '100%' }} />
            </Box>
            :
            <>
                <TableContainer component={Paper}>
                    <Box display={'flex'} justifyContent={'space-between'} flexDirection={'row'} >
                        <Typography variant='h5' m={2} >Assets</Typography>
                        <Button variant='text' onClick={printAll} >Print All Qr Codes</Button>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Asset Code</TableCell>
                                <TableCell>Equipment Tag</TableCell>
                                <TableCell>Supplier Name</TableCell>
                                <TableCell>Invoice No</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Date of Acquisition</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Remark 1</TableCell>
                                <TableCell>Remark 2</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assets.map((asset, index) => (
                                <TableRow key={asset.id}>
                                    <TableCell>{asset.assetCode}</TableCell>
                                    <TableCell>{asset.equipmentsTag}</TableCell>
                                    <TableCell>{asset.supplierName}</TableCell>
                                    <TableCell>{asset.invoiceNo}</TableCell>
                                    <TableCell>{asset.description}</TableCell>
                                    <TableCell>{asset.qty}</TableCell>
                                    <TableCell>{asset.dateOfAcquiSition}</TableCell>
                                    <TableCell>{asset.location}</TableCell>
                                    <TableCell>{asset.value}</TableCell>
                                    <TableCell>{asset.remark1}</TableCell>
                                    <TableCell>{asset.remark2}</TableCell>
                                    <TableCell>
                                        <IconButton
                                            aria-label="more"
                                            aria-controls="long-menu"
                                            aria-haspopup="true"
                                            onClick={(event) => handleMenuOpen(event, asset)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => {
                            navigate(`/panel/edit/${currentAsset.id}`)
                            handleMenuClose();
                        }}>Edit</MenuItem>
                        <MenuItem onClick={handleOpenDialog}>Delete</MenuItem>
                        <MenuItem onClick={handlePrintQRCode}>Print QR Code</MenuItem>
                    </Menu>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={totalAssets}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                />
            </>
        }
        <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: 16, right: 16 }} onClick={() => navigate('/panel/add')}>
            <AddIcon />
        </Fab>

        <Fab color="success" aria-label="upload" style={{ position: 'fixed', bottom: 16, right: 86 }} onClick={triggerFileInput}>
            <Upload />
        </Fab>

        <input
            accept=".xlsx, .xls"
            style={{ display: 'none' }}
            id="file-upload"
            type="file"
            onChange={handleFileUpload}
        />


        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to delete this asset?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleDeleteAsset} variant="contained" color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>

        {currentAsset && (
            <Box display={'none'}>
                <Box ref={qrRef} display={'flex'} flexDirection={'column'} sx={{ width: '2in', height: '2in', padding: '10px', alignItems: 'center', justifyContent: 'center' }} >
                    <QRCode value={currentAsset.equipmentsTag} size={128} />
                    <Typography sx={{ color: 'black', marginTop: '10px', fontSize: '14px' }}>{currentAsset.equipmentsTag}</Typography>
                </Box>
            </Box>
        )}


        <Box display={'none'}>
            <Box ref={qrAllRef} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={1} justifyContent={'center'}>
                {
                    assetQrCodes.map((qrText, index) => (
                        <Box display={'flex'} flexDirection={'column'} sx={{ flex: '1 1 auto', width: '1.85in', height: '2in', padding: '5px', alignItems: 'center', justifyContent: 'center', boxSizing: 'border-box', pageBreakInside: 'avoid' }} >
                            <QRCode value={qrText} size={128} />
                            <Typography sx={{ color: 'black', marginTop: '10px', fontSize: '14px' }}>{qrText}</Typography>
                        </Box>
                    ))
                }
            </Box>
        </Box>


        <Snackbar
            open={message != null}
            autoHideDuration={6000}
            onClose={() => { setMessage(null) }}
            message={message}
        />
    </>);
};