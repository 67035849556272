import { Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const Logout = () => {
    
    const { logout } = useAuth();
  
    logout();

    return (
      <Navigate to={"/login"} replace></Navigate>
    );
  };
  
export default Logout;