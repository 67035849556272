// src/Login.js
import React, { useState } from 'react';
import { Button, TextField, Typography, Box, Grid, Paper, Link, Fab } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import api from '../../api/api';
import { useAuth } from '../../contexts/AuthContext';
import { useTheme } from '../../contexts/ThemeContext';

const Login = () => {
  const { mode, toggleTheme } = useTheme();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    api.post('/api/Auth/login', { email: email, password: password })
      .then(response => {
        var token = response.data?.token
        console.log('Token:', token);
        const userData = jwtDecode(token);
        console.log('User data:', userData);
        if(userData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] != 'Admin'){
          setError('Only Admin User Allowed to Login.');
        } else{
          login(token);
          navigate('/panel/');
        }
      })
      .catch(error => {
        console.error('Error fetching managers', error);
        setError('Login failed. Please check your credentials and try again.');
      });
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/images/login_bg.webp)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{
            backgroundImage: 'url(/images/logo_anim.svg)'
          }}
            className="LogoAnim play" />
          <Fab size='small' sx={{
            position: 'absolute',
            top: 10,
            right: 10
          }}
            color="primary"
            onClick={toggleTheme}>
            {mode == 'light' ? <DarkModeIcon /> : <LightModeIcon />}
          </Fab>
          <Typography component="h1" variant="h5">
            Welcome to <span style={{ color: 'primary' }}>Asset App</span>
          </Typography>
          <Typography component="p" variant="body2" sx={{ mt: 2 }}>
            Log in to get in the area to manage your assets needs.
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            {error && <Typography color="error">{error}</Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleLogin}
            >
              Sign In
            </Button>
            { /*<Grid container justifyContent={'end'}>
              <Grid item>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
            </Grid> */ }
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
