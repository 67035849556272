// src/ThemeContext.js
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { createTheme, ThemeProvider, CssBaseline, colors } from '@mui/material';

export const ThemeContext = createContext();

export const ThemeContextProvider = ({ children }) => {
  const [mode, setMode] = useState(() => {
    return localStorage.getItem('theme') || 'dark';
  });

  useEffect(() => {
    localStorage.setItem('theme', mode);
  }, [mode]);

  const baseTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: '#ffac25', // Primary color
      },
      secondary: {
        main: '#ffc64a', // Secondary color
      },
      text: {
        primary: mode === 'light' ? '#000' : '#fff',
      },
      background: {
        default: mode === 'light' ? '#FFF8DC' : '#3B3B3B', // Background color
        paper: mode === 'light' ? '#fffce0' : '#3b3b3b', // Paper background color
        header: mode === 'light' ? '#ffc64a' : '#313131',
      },
    }
  })

  const theme = useMemo(
    () =>
      createTheme(baseTheme, {
        components: {
          MuiPaper: {
            styleOverrides: { root: { backgroundImage: 'unset' } },
          },
          MuiCssBaseline: {
            styleOverrides: {
              'input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button': {
                WebkitAppearance: 'none',
                margin: 0,
              },
              'input[type=number]': {
                MozAppearance: 'textfield',
              },
            },
          },
          MuiStepIcon: {
            styleOverrides: {
              root: {
                '&.Mui-completed': {
                  color: baseTheme.palette.success.main,
                },
                '&.Mui-error': {
                  color: baseTheme.palette.error.main,
                },
              },
            },
          },
          MuiTableCell: {
            styleOverrides: {
              head: {
                background: baseTheme.palette.background.header,
              }
            }
          }
        },
      }),
    [mode]
  );

  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};


export const useTheme = () => useContext(ThemeContext);